@media only screen and (max-width: 6000px) {
    /* Mobile-specific styles or behavior changes */
    .toolbarHeader {
        display: None;
    }
  }

h1, p, h2, link {
    font-family: "Times New Roman", Times, serif;
}

p {
    font-size: "80px";
}

html {
    overflow-x: hidden;
}