
* {
  margin: 0;
  padding: 0;
}

.NavigationLink {
  cursor: pointer;
}

.theme-font {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.LogoArea {
  padding-top: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.InfoAreaField {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

/* @media screen and (max-width:760px) {
  .InfoAreaField {
      padding-top: 50px;
      padding-bottom: 50px;
  }
} */

.bg-light-blue {
  background-color: rgba(255, 255, 255, 0.742);
}

.bg-brown {
  /* background-color: rgb(153, 116, 4); */
  background-color: rgb(255, 238, 186);
}

.bg-light-brown {
  background-color: rgb(255, 238, 186);
}

.text-light  {
  color: black;
  font-weight: bold;
  font-family: 'Poppins', sans-serif !important;
}

.text-dark-custom  {
  color:  rgb(0, 0, 0);
  font-weight: bold;
}

.FinanceSection {
  background-color: aqua ;
}

/* .FinanceAreaText {
  padding-top: 150px;
  padding-bottom: 150px;
  font-size: large;
  font-family: Georgia, 'Times New Roman', Times, serif;
} */

/* @media screen and (max-width:760px) {
  .FinanceAreaText {
      padding-top: 50px;
      padding-bottom: 50px;
  }

  #HouseBanner {
      visibility: hidden;
      display: none;
  }
} */

.margin-inner {

  justify-content: center;
  justify-items: center;
  align-items: center;
}

.theme-align {
  max-width: 1220px;
}

body {
  font-family: Georgia, 'Times New Roman', Times, serif;
}


/* Row Designs */
#titleRow {
  background-color: rgb(255, 255, 255);
}

/* #infoRow {
  background-color: rgb(255, 255, 255);
} */

#Footer {
  padding-left: 0;
  padding-right: 0;
}

.Container1 {
  background-color: rgb(255, 255, 255);
}
/* 
.App-logo {
height: 40vmin;
pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
.App-logo {
  animation: App-logo-spin infinite 20s linear;
}
}

.App-header {
background-color: #282c34;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}

.App-link {
color: #61dafb;
}

.carousel {
  position: relative;
  width:100%;
  height:360px;
}

.slide {
  position: relative;
  height: 500px;
}

.WCRSlideShow {
  position: static;
  display: block;
  height: 500px;
}

.WCRCarousel {
  position: absolute;
  width: 100%;
  height: 20%;
}

.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-inner > .carousel-item {
  position: relative;
  width: 100%;
  height: 90%;
}

.carousel-inner > .carousel-item > img {
  position: relative;
  width: 100%;
}

.p {
  font-family: "Times New Roman", Times, serif;
}

.MainPage {
  position: relative;
}

.test_css {
  text-align: center;
}

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}


@keyframes App-logo-spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
} */
